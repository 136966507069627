const progress = () => {
    const els = document.querySelectorAll('.progress__item')
    els.forEach(el => {
        const observer = new window.IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
            el.classList.add("--in-view");
            observer.unobserve(el);
            return
        }
        }, {
        root: null,
        threshold: 0.5, // set offset 0.1 means trigger if atleast 10% of element in viewport
        })

        observer.observe(el);
    });

}

$(function () {
    setHeaderScroll();
    hamburger();
    slick();
    progress();
    scrollTevealInit();

});



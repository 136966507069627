// Slick

const slick = () => {
    $('.references__slick').slick({
        dots: false,
        speed: 300,
        slidesToShow: 1,
        prevArrow: $('.slick__arrow.arrow__prev.reference__arrow'),
        nextArrow: $('.slick__arrow.arrow__next.reference__arrow')
    });
}
